$max: 99999 !default;
$primary: rgb(24, 168, 167);
@mixin c-scrollbar-w-($size) {
  &::-webkit-scrollbar {
    width: $size/4 * 1rem;
  }
}

.c-zIndexMax {
  z-index: $max;
}
.form-check {
  & input[name="customSwitch"] {
    &.form-check-input:checked {
      background-color: $primary;
    }
  }
}

.c-card-scroll {
  & div {
    max-height: calc(100vh - 35.15rem);
  }
  overflow-y: auto;
  @include c-scrollbar-w-(1.5);
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 6px white;
    opacity: 0;

    background-color: white;
    &:hover {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

      background-color: #b5b2b2;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.vertical-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.truncate {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@each $step in 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
  85, 90, 95, 100, 125, 150, 200, 250, 300
{
  .mr-#{$step} {
    margin-right: #{$step + "px"};
  }
  .ml-#{$step} {
    margin-left: #{$step + "px"};
  }
}

@each $step in 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
  85, 90, 95, 100
{
  .w-#{$step} {
    width: #{$step + "%"};
  }

  .w-max-#{$step} {
    max-width: #{$step + "%"};
  }

  .w-min-#{$step} {
    min-width: #{$step + "%"};
  }
}

@each $step in 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40
{
  .cfz-#{$step} {
    font-size: #{$step + "px"};
  }
}
@each $step in 100, 200, 300, 400, 500, 600, 700, 800, 900 {
  .cfbold-#{$step} {
    font-weight: #{$step};
  }
}
@each $step in 60, 65, 70, 75, 80, 85, 90, 95, 100 {
  .chv-#{$step} {
    height: #{$step + "vh"};
    max-height: 100%;
  }
  .chp-#{$step} {
    height: #{$step + "%"};
    max-height: 100%;
  }
}

// Width by an absolute value
@each $step in 0, 10, 20, 30, 40, 50, 70, 75, 80, 85, 90, 95, 100, 110, 115, 120,
  150, 160, 170, 200, 220, 240, 250, 260, 270, 300, 310, 320, 350, 360, 380, 400,
  450, 480, 500, 550, 600, 620, 640, 650, 700, 750, 800, 850, 900, 950, 960,
  1000, 1040, 1120, 1200, 1280, 1360, 1440, 1520, 1600
{
  .w-abs-max-#{$step} {
    max-width: #{$step/10 + "rem"} !important;
  }

  .w-abs-min-#{$step} {
    min-width: #{$step/10 + "rem"} !important;
  }

  .w-abs-#{$step} {
    max-width: 100%;
    width: #{$step/10 + "rem"} !important;
  }
}
.editor {
  .wrapper-editor {
    .block-editor {
      height: 250px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px white;
        border-radius: 10px;
        background-color: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;

        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        opacity: 0;

        background-color: white;
        &:hover {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

          background-color: #b5b2b2;
        }
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
    & .block-editor.is-showCode {
      display: none;
    }
    .rdw-editor-toolbar.is-showCode {
      div {
        background-color: #bfbdbd;
        border: none;
        cursor: auto;
        * {
          border: none;
          cursor: auto;
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}

.editor.is-invalid {
  .wrapper-editor {
    border-radius: 5px;
    border: 0.5px solid #ea5455;
    .rdw-editor-toolbar {
      border-bottom: 0.5px solid #ea5455;
    }
  }
}

.block-prview {
  height: 350px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 6px white;
    opacity: 0;

    background-color: white;
    &:hover {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

      background-color: #b5b2b2;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.banner-image {
  border: 0.5px solid #ebe9f1;
  border-radius: 5px;
  &.is-invalid {
    border-radius: 5px;
    border: 0.5px solid #ea5455 !important;
  }
}

.c-flatPicker {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus {
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
  &.is-invalid {
    border: 1px solid #ea5455;
  }
  svg {
    cursor: pointer;
  }
  .icon-calendar {
    display: inline-block;
    margin-right: 8px;
  }
  .icon-close {
    display: inline-block;
    margin-left: 8px;
  }
  .cf-close {
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    &.-active {
      opacity: 1;
      visibility: visible;
    }
  }
  .input-button {
    &.is-invalid {
      color: #ea5455;
    }
  }
  input {
    flex: 1;
    padding: 0;
    margin: 0;
    border: none;
    max-width: 110px;
    &:focus-visible {
      outline: none;
    }
  }
}

.c-tag-input {
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 5px;
  font-size: 0.9rem;
  height: 50px;
  padding: 0 10px;
  &__icon {
    cursor: pointer;
  }
  &__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 8px;
    border-radius: 5px;
    & > span {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  &__text {
    border: none;
    outline: none;
    font-size: 13px;
    line-height: 50px;
    background: none;
  }
}

.avarta-image {
  border: 0.5px solid #ebe9f1;
  border-radius: 50%;
  &.is-invalid {
    border-radius: 50%;
    border: 0.5px solid #ea5455 !important;
  }
  img {
    max-width: auto !important;
    min-height: auto !important;
  }
}

// END
